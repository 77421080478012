import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set, update, get } from "firebase/database";
import NodeVideo from '../assets/TokenArt/4-Single-Square.mp4'; // Import the video file
import '../styles/justIncredible.css';

const JustIncredible = () => {
  const [answers, setAnswers] = useState({
    partyWithJustin: '',
    wouldYouLikeToParty: '',
    favoriteContent: '',
    whyFavoriteDJ: '',
  });
  const [showModal, setShowModal] = useState(false); // State to show or hide the modal

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnswers((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (user) {
      const userId = user.uid;
      const db = getDatabase();
      const surveyRef = ref(db, `users/${userId}/justincredible_survey`);
      const nodesRef = ref(db, `users/${userId}/nodes/event1`);
      const pointsRef = ref(db, `users/${userId}/nodes/event1/points`);
      const totalPointsRef = ref(db, `users/${userId}/points`);

      // Store the survey responses
      set(surveyRef, answers).then(() => {
        // After storing survey, update Node 1 to visited and award 1 point
        get(nodesRef).then((snapshot) => {
          if (snapshot.exists()) {
            let updates = {};
            let nodeData = snapshot.val();

            // If Node 1 is not already visited, mark it as visited
            if (nodeData.node1 !== 'visited') {
              updates[`/users/${userId}/nodes/event1/node1`] = 'visited';

              // Update event-specific points (increment by 1)
              get(pointsRef).then(pointsSnapshot => {
                let currentEventPoints = pointsSnapshot.exists() ? pointsSnapshot.val() : 0;
                updates[`/users/${userId}/nodes/event1/points`] = currentEventPoints + 1;

                // Update total points (increment by 1)
                get(totalPointsRef).then(totalPointsSnapshot => {
                  let totalPoints = totalPointsSnapshot.exists() ? totalPointsSnapshot.val() : 0;
                  updates[`/users/${userId}/points`] = totalPoints + 1;

                  // Apply all updates in one go
                  return update(ref(db), updates);
                }).then(() => {
                  console.log("Survey stored, node 1 unlocked, and points awarded.");
                  setShowModal(true); // Show the modal after successful submission
                });
              });
            } else {
              console.log("Node 1 already visited. Only storing survey.");
              setShowModal(true); // Show the modal even if node is already visited
            }
          } else {
            // Initialize nodes if they don't exist
            set(nodesRef, {
              node1: 'visited',
              node2: 'unvisited',
              node3: 'unvisited',
              points: 1,
              fullyCompleted: false
            }).then(() => {
              // Also increment total points
              get(totalPointsRef).then(totalPointsSnapshot => {
                let totalPoints = totalPointsSnapshot.exists() ? totalPointsSnapshot.val() : 0;
                update(ref(db, `users/${userId}/points`), totalPoints + 1).then(() => {
                  console.log("Nodes initialized, node 1 unlocked, and points awarded.");
                  setShowModal(true); // Show the modal after initializing nodes
                });
              });
            });
          }
        });
      }).catch((error) => {
        console.error('Error storing survey or updating nodes:', error);
      });
    }
  };

  // Function to close the modal and redirect to /map
  const handleContinue = () => {
    setShowModal(false);
    navigate('/map');
  };

  return (
    <div className="survey-container">
      <form onSubmit={handleSubmit} className="survey-form">
        <header className='justin-header'>Justin Credible Survey</header>
        <div className="question-block">
          <label>1a: Have you ever partied with Justin Credible in Vegas?</label>
          <div>
            <input 
              type="radio" 
              name="partyWithJustin" 
              value="Yes" 
              onChange={handleChange} 
              checked={answers.partyWithJustin === 'Yes'}
            /> Yes!
            <input 
              type="radio" 
              name="partyWithJustin" 
              value="Not yet" 
              onChange={handleChange} 
              checked={answers.partyWithJustin === 'Not yet'}
            /> Not yet.
          </div>
        </div>

        {answers.partyWithJustin === 'Not yet' && (
          <div className="question-block">
            <label>1b: Would you like to?</label>
            <div>
              <input 
                type="radio" 
                name="wouldYouLikeToParty" 
                value="Hell yah!" 
                onChange={handleChange} 
                checked={answers.wouldYouLikeToParty === 'Hell yah!'}/>
              Hell yeah!
              <input 
                type="radio" 
                name="wouldYouLikeToParty" 
                value="I’d rather catch the BTS recap" 
                onChange={handleChange} 
                checked={answers.wouldYouLikeToParty === 'I’d rather catch the BTS recap'}/>
              I’d rather catch the BTS recap.
            </div>
          </div>
        )}

        <div className="question-block">
          <label>2: What is your favorite Justin Credible BTS content?</label>
          <div>
            <input 
              type="radio" 
              name="favoriteContent" 
              value="Freestyle" 
              onChange={handleChange} 
              checked={answers.favoriteContent === 'Freestyle'}/> Freestyle
            <input 
              type="radio" 
              name="favoriteContent" 
              value="Up in the Club" 
              onChange={handleChange} 
              checked={answers.favoriteContent === 'Up in the Club'}/> Up in the Club
            <input 
              type="radio" 
              name="favoriteContent" 
              value="Radio" 
              onChange={handleChange} 
              checked={answers.favoriteContent === 'Radio'}/> Radio
            <input 
              type="radio" 
              name="favoriteContent" 
              value="I love it all!" 
              onChange={handleChange} 
              checked={answers.favoriteContent === 'I love it all!'}/> I love it all!
          </div>
        </div>

        <div className="question-block">
          <label>3: Why is Justin Credible your favorite DJ?</label>
          <textarea 
            name="whyFavoriteDJ" 
            value={answers.whyFavoriteDJ} 
            onChange={handleChange}
            rows="4"
            placeholder="Enter your response here"/>
        </div>

        <div className="submit-block">
          <button type="submit" className="submit-btn">Submit</button>
        </div>
      </form>

      {/* Popup Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Congratulations!</h2>
            <p>You just won your first Cutty reward. Visit the progress page to see it!</p>
            {/* Insert Node Video here */}
            <video src={NodeVideo} className="modal-video" autoPlay muted loop playsInline/>
            <button className="modal-button" onClick={handleContinue}>Continue</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JustIncredible;
