import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import button1Image from '../assets/newIcons/cameraIcon.png';
import button2Image from '../assets/newIcons/exploreIcon.png';
import button3Image from '../assets/newIcons/progressPage.png';
import button4Image from '../assets/newIcons/inventoryIcon.png';
import button5Image from '../assets/newIcons/profileIcon.png';
import button1ImageHover from '../assets/newIcons/cameraIconActive.png';
import button2ImageHover from '../assets/newIcons/exploreIconActive.png';
import button3ImageHover from '../assets/newIcons/progressPageActive.png';
import button4ImageHover from '../assets/newIcons/inventoryIconActive.png';
import button5ImageHover from '../assets/newIcons/profileIconActive.png';
import "../styles/bottombar.css"

function BottomBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (paths) => paths.includes(location.pathname);
    
    return (
      <div className="bottomBarContainer">

        <button id="exploreBtn" className="icon-button" onClick={() => navigate('/map')}>
          <img className={isActive('/map') ? "active" : "default"} src={isActive('/map') ? button2ImageHover : button2Image} alt="Button 2" />
        </button>

        <button id="cuttyButton" className="icon-button" onClick={() => navigate('/progress')}>
          <img className={isActive('/progress') ? "active" : "default"} src={isActive('/progress') ? button3ImageHover : button3Image} alt="Button 3" />
        </button>

        <button id="cameraBtn" className="icon-button" onClick={() => navigate('/qrscan')}>
          <img className={isActive('/qrscan') ? "active" : "default"} src={isActive('/qrscan') ? button1ImageHover : button1Image} alt="Button 1" />
        </button>

        <button id="inventoryBtn" className="icon-button" onClick={() => navigate('/rewards')}>
          <img className={isActive('/rewards') ? "active" : "default"} src={isActive('/rewards') ? button4ImageHover : button4Image} alt="Button 4" />
        </button>

        <button id="profileBtn" className="icon-button" onClick={() => navigate('/account')}>
          <img className={isActive('/account') ? "active" : "default"} src={isActive('/account') ? button5ImageHover : button5Image} alt="Button 5" />
        </button>
      </div>
    );
  }
  
  export default BottomBar;