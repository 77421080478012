import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, update, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function UpdateNodesPage2() {
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const auth = getAuth();

    onAuthStateChanged(auth, user => {
      if (user) {
        const userId = user.uid;
        const userRef = ref(db, `users/${userId}/nodes/event1`);
        const pointsRef = ref(db, `users/${userId}/nodes/event1/points`);
        const totalPointsRef = ref(db, `users/${userId}/points`);

        get(userRef).then((snapshot) => {
          if (snapshot.exists()) {
            let nodeData = snapshot.val();

            if (nodeData.node2 === "visited") {
              console.log("Node2 has already been visited. No points awarded.");
              navigate('/progress');
              return; // Exit the function if node3 is already visited
          }

            let updates = {};
            updates[`/users/${userId}/nodes/event1/node2`] = "visited";
            updates[`/users/${userId}/nodes/event1/fullyCompleted`] = nodeData.node1 === 'visited' && nodeData.node3 === 'visited';

            // Use a separate get call to retrieve and increment the points
            get(pointsRef).then(pointsSnapshot => {
              let currentEventPoints = pointsSnapshot.exists() ? pointsSnapshot.val() : 0;
              updates[`/users/${userId}/nodes/event1/points`] = currentEventPoints + 1;

              // Retrieve and increment the total points
              get(totalPointsRef).then(totalPointsSnapshot => {
                let totalPoints = totalPointsSnapshot.exists() ? totalPointsSnapshot.val() : 0;
                updates[`/users/${userId}/points`] = totalPoints + 1;

                // Perform the updates for nodes and points in a single update call
                return update(ref(db), updates);
              });

            }).then(() => {
              console.log("Nodes and points updated successfully.");
              navigate('/node2-question');
            }).catch(error => {
              console.error("Failed to update nodes and points:", error);
            });

          } else {
            console.log("No data available for nodes");
            // Handle the case where there are no nodes data
            // Possibly you want to initialize it or handle as an error
          }
        }).catch((error) => {
          console.error("Error fetching nodes data:", error);
        });

      } else {
        console.log('No user is signed in.');
      }
    });
  }, [navigate]);

  return null;
}

export default UpdateNodesPage2;
