import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import logoImage from './assets/newAssets/icons/cuttyTopLeft.png';
import './App.css';
import SignIn from './components/SignIn.jsx';
import CreateAccount from './components/CreateAccount.jsx';
import MyMapComponent from './components/mapPage.jsx';
import AccountPage from './components/accountPage.jsx';
import LandingPage from './components/landingPage.jsx';
import QRScanner from './components/QRScanner.jsx';
import BottomBar from './components/bottomBar.jsx';
import RewardsPage from './components/rewardsPage.jsx';
import UpdateNodesPage1 from './components/node1.jsx';
import UpdateNodesPage2 from './components/node2.jsx';
import UpdateNodesPage3 from './components/node3.jsx';
import WalletPage from './components/walletPage.jsx';
import DisplayPage from './components/digitalCollectibles.jsx';
import Leaderboard from './components/leaderboard.jsx';
import NodeUpdatedPage1 from './components/node1-question.jsx';
import NodeUpdatedPage2 from './components/node2-question.jsx';
import NodeUpdatedPage3 from './components/node3-question.jsx';
import NodeRewardsPage from './components/nodeRewardsPage.jsx';
import RedeemRewards from './components/redeemProgress.jsx';
import RewardVideoPage from './components/rewardVideoPage.jsx';
import HomePage from './components/homePage.jsx';
import JustinCredible from './components/justinCredible.jsx';
//import { Web3ReactProvider } from '@web3-react/core';
//import Web3 from 'web3';
//import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
//import { ethers } from 'ethers';
//import { Buffer } from 'buffer';

/*

window.Buffer = Buffer;

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

const projectId = 'e202a541ec6d1848de23204b908695a7'

const chains = [1, 5]

const metadata = {
  name: 'CUTTY',
  description: 'Cutty - Explore more, spend less',
  url: 'https://cutty.xyz',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains,
  projectId,
  themeVariables: {
    '--w3m-accent': '#F0523E', 
    '--w3m-color-mix': '#ff0000',
    '--w3m-color-mix-strength': 40,
    '--w3m-z-index': 10000,
  }
})

*/

function Main() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 800);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 800);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isDesktop) {
    return (
      <DesktopPage />
    )
  } else {
    return (
      <MobilePage />
    )
  }
  }

function DesktopPage() {
  return (
    <div className='desktopPageFrame'>
      <img src={logoImage} className="logoImageDesktop" />
      <div className='desktopPage'>
        <div className='desktopPageContainer'>
          <header className='desktopPageText'>For the full Cutty experience <br/> please open on a mobile device.</header>
          <span className='desktopPageLowerText'>Alternatively, use inspect element to view the page in smaller dimensions</span>
        </div>
      </div>
    </div>
  )
}

function MobilePage() {
  const location = useLocation();
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/justincrediblefan" element={<CreateAccount />} />
        <Route path="/justincredible" element={<CreateAccount />} />
        <Route path="/justincredible-survey" element={<JustinCredible />} />
        <Route path="/map" element={<MyMapComponent />} />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/qrscan" element={<QRScanner />} />
        <Route path="/progress" element={<RewardsPage />} />
        <Route path="/walletconnect" element={<WalletPage />} />
        <Route path="/a4348418" element={<UpdateNodesPage1 />} />
        <Route path="/b335e048" element={<UpdateNodesPage2 />} />
        <Route path="/c04ba73e" element={<UpdateNodesPage3 />} />
        <Route path="/rewards" element={<DisplayPage />} />
        <Route path="/leaderboard" element={<Leaderboard/>} />
        <Route path="/node1-question" element={<NodeUpdatedPage1/>} />
        <Route path="/node2-question" element={<NodeUpdatedPage2/>} />
        <Route path="/node3-question" element={<NodeUpdatedPage3/>} />
        <Route path="/node-rewards" element={<NodeRewardsPage/>} />
        <Route path="/redeem-rewards" element={<RedeemRewards/>} />
        <Route path="/reward-video" element={<RewardVideoPage />} />
        <Route path="/home-page" element={<HomePage />} />
      </Routes>
      {location.pathname !== "/" && location.pathname !== "/create-account" && location.pathname !== "/home-page" && location.pathname !== "/landingpage" && location.pathname !== "/justincredible" && <BottomBar />}
    </>
  );
}

function App() {
  return (
      <Router>
        <Main />
      </Router>
  );
}

export default App;
