import React, { useState, useEffect } from 'react'; // Import useEffect
import { getDatabase, ref, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/nodeQuestion.css'; // Make sure to create this CSS file
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';

function NodeUpdatedPage1() {
  const [selectedOption, setSelectedOption] = useState('');
  const [bottomText, setBottomText] = useState(''); // New state for the bottom text
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOption === '') {
      setBottomText('Please select an option');
    } else {
      setBottomText(''); // or any other message
    }
  }, [selectedOption]);

  const handleRedeemClick = () => {
    // Get the current user
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const db = getDatabase();
      const selectionRef = ref(db, `users/${user.uid}/nodes/event1/node1Selection`);

      // Write the selection to Firebase
      set(selectionRef, selectedOption)
        .then(() => {
          console.log("Selection saved successfully.");
          navigate('/node-rewards')
        })
        .catch((error) => {
          console.error("Error saving selection:", error);
        });
    } else {
      console.log('No user is signed in.');
      navigate('/')
    }
  };

  const isOptionSelected = selectedOption !== '';

  return (
    <div className="frameQuestion">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div className='node-updated-bg'>
        <div className="node-updated-container">
          <div className="white-container">
            <header className='node-question-header'>Please make a selection for your node 1 reward (Goodies Table)</header>
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="node-updated-dropdown"
            >
              <option value="">Select an option...</option>
              <option value="Psilo Gummies">Psilo (Psilocybin Gummies)</option>
              <option value="GHOSTPACK Flower">GHOSTPACK (Flower 1/8th)</option>
              <option value="GHOSTPACK Preroll">GHOSTPACK (Pre-roll 1g)</option>
              <option value="Cookies Maywood">Cookies Maywood (Flower 1/8th)</option>
              <option value="The Standard">The Standard by Farmdale (Hash-Infused Pre-roll 0.5g)</option>
              <option value="Not My Style">Nah, not my style</option>
            </select>
            <button 
              className='redeemNodeProgress' 
              onClick={handleRedeemClick}
              disabled={!isOptionSelected}  // Disable the button if no option is selected
            >          
            Redeem
            </button>
          </div>
          <span>{bottomText}</span>
        </div>
      </div>
    </div>
  );
}

export default NodeUpdatedPage1;
