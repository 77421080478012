import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';  // import useNavigate
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import app from '../firebase.js';
import '../styles/SignIn.css'; 
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';

function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // new state for error message

  const navigate = useNavigate();  // use navigate function
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const auth = getAuth(app);
    
    try {
      // Set persistence to LOCAL
      await setPersistence(auth, browserLocalPersistence);

      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      console.log(userCredential.user); 
      setErrorMessage("");

      // If the sign in was successful, navigate to the map page
      navigate('/landingpage');
      
    } catch (error) {
      console.error("Error signing in with email and password", error);
      setErrorMessage("Error signing in with email and password, ensure you are using your email and the password is correct.");
    }
  };

  return (
    <div className="signInBackground">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div className="signInContainer">
        <header className="signInHeader">Sign in</header>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Email" value={username} onChange={e => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <button type="submit" id="signUp">Sign in</button>
        </form>
        {errorMessage && <h6 className="error">{errorMessage}</h6>} {/* Show error message if it exists */}
        <h3>Don't have a Cutty account?</h3>
        <Link to="/create-account" id="createAccount">Create an account</Link>
      </div>
    </div>
  );
}

export default SignIn;
