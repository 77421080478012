import React, { useState, useRef, useEffect } from 'react';
import jsQR from 'jsqr';
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import '../styles/QRScanner.css';

function QRScanner() {
  const videoRef = useRef();
  const [qrData, setQrData] = useState(null);

  useEffect(() => {
    let shouldScan = true;
    let stream;

    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(mediaStream => {
        stream = mediaStream;
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }).catch((error) => {
        console.log('Error accessing camera:', error);
      });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const scan = () => {
      if (!document.hidden && videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        canvas.height = videoRef.current.videoHeight;
        canvas.width = videoRef.current.videoWidth;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

        if (qrCode && qrCode.data.startsWith('https://cutty.xyz/')) {
          console.log(`QR Code detected: ${qrCode.data}`);
          setQrData(qrCode.data);
          window.location.href = qrCode.data; // Redirect to the URL
        }
      }

      if (shouldScan) requestAnimationFrame(scan);
    };

    scan();

    return () => {
      shouldScan = false;
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <div className="frameQR">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div style={{ position: 'relative' }}>
        <video className='camera' ref={videoRef} style={{ width: '100%', height: 'auto' }} playsInline />
        {qrData && 
          <div style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', background: 'rgba(0, 0, 0, 0.5)', padding: '10px', borderRadius: '5px' }}>
            QR Code detected: {qrData}
          </div>
        }
      </div>
    </div>
  );
}

export default QRScanner;
