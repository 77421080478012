import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import app from '../firebase.js'
import { remove, getDatabase, ref, onValue, update } from "firebase/database";
import { deleteUser, getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider, onAuthStateChanged } from "firebase/auth";
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import { confirmAlert, css } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import '../styles/accountPage.css';
import { IoIosArrowBack, IoIosSave } from "react-icons/io";
import InputMask from 'react-input-mask';
import profileIcon from '../assets/cuttyProfile.png'
import saveIcon from '../assets/cutty arrow.png'

function AccountPage() {
  const [userDetails, setUserDetails] = useState({
    email: "",
    phoneNumber: "",
    birthDate: "",
    username: ""
  });
  const [message, setSuccessMessage] = useState("");
  const [editableDetails, setEditableDetails] = useState({
    phoneNumber: "",
    birthDate: "",
    username: ""
  });
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  
  const navigate = useNavigate();
  const auth = getAuth(app);
  const database = getDatabase(app);
  const users = ref(database, 'users');
  
  const handleSavePassword = async () => {
    const user = auth.currentUser;
    
    if (user) {
      if(passwords.newPassword !== passwords.confirmNewPassword) {
        alert('Passwords do not match');
        return;
      }
    
      // Now, reauthenticate the user
      const credential = EmailAuthProvider.credential(user.email, passwords.currentPassword);
      
      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, passwords.newPassword);
        setSuccessMessage('Password updated successfully');
      } catch (error) {
        setSuccessMessage('Error updating password, ensure the passwords match and your current password is correct');
      }
    }
  };


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userRef = ref(database, 'users/' + user.uid);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          setUserDetails({
            email: data.email,
            username: data.username,
            phoneNumber: data.phoneNumber,
            birthDate: data.birthDate
          });
          // Set editableDetails including username from the fetched data
          setEditableDetails({
            phoneNumber: data.phoneNumber,
            birthDate: data.birthDate,
            username: data.username || "", // make sure 'username' is the key you've used in your database
          });
        });
      } else {
        navigate('/');
      }
    });
  }, [auth, database, navigate]);
  


  const handleSaveChanges = async () => {
    // Get the current authenticated user
    const user = auth.currentUser;
    if (user) {
      // Define the user ref
      const userRef = ref(database, 'users/' + user.uid);
      
      // Define the updated user data
      const updatedData = {
        phoneNumber: editableDetails.phoneNumber,
        birthDate: editableDetails.birthDate,
        username: editableDetails.username
      };
  
      // Update the data in Firebase
      try {
        await update(userRef, updatedData);
        setSuccessMessage('Data updated successfully');
      } catch (error) {
        setSuccessMessage("Error updating data: ", error);
      }
    }
  };

  const handleDeleteAccount = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui' style={{width: '300px'}}>
            <h1>Confirm to delete</h1>
            <p>Are you sure you want to delete your account?</p>
            <button className='confirmButton' onClick={onClose}>No</button>
            <button className='confirmButton'
              onClick={async () => {
                const user = auth.currentUser;
                if (user) {
                  const password = prompt("Please enter your password to confirm:");
                  // Define the user ref
                  if(password){
                    const userRef = ref(database, 'users/' + user.uid);
                    const credential = EmailAuthProvider.credential(user.email, password);

                    // Delete the user from Firebase Authentication
                    try {
                      await reauthenticateWithCredential(user, credential);
                      await deleteUser(user);
                      await remove(userRef);
                      navigate('/');
                    } catch (error) {
                      setSuccessMessage("Error deleting user. Please reintialize your login session and retry deleting your account.");
                      navigate('/');
                    }
                  }
                }
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {}
    });
  };
  
  return (
    <div className="frameAccount">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div className="menuIcon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
          ☰
          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div onClick={() => navigate('/leaderboard')}>Leaderboard</div>
              <div onClick={() => navigate('/node-rewards')}>Node Rewards</div>
            </div>
          )}
        </div>
      <div className="accountPageBackground">
        <div className="accountPageContainer">
          <div className='userDetails'>
            <img src={profileIcon} className="profileIcon" alt="profileLogo" />
            <strong><header className='userEmail'>{userDetails.email}</header></strong><br></br>
          </div>
          <div className="inputContainer">
            <input 
              className="accountPageInput" 
              type="text" 
              placeholder="Username"
              value={editableDetails.username} 
              onChange={(e) => setEditableDetails({...editableDetails, username: e.target.value})} 
            />
            {/* You can either use the save icon per input or have one save button for all */}
            <img 
              src={saveIcon} 
              alt="Save Icon" 
              onClick={handleSaveChanges} // this could be a button to save all changes
              className="saveIcon"
            />
          </div>
          <div className="inputContainer">
            <InputMask
              className="accountPageInput"
              mask="(999) 999-9999" 
              placeholder="Phone Number" 
              value={editableDetails.phoneNumber} 
              onChange={(e) => setEditableDetails({...editableDetails, phoneNumber: e.target.value})}
            />
          <img 
              src={saveIcon} 
              alt="Save Icon" 
              onClick={handleSaveChanges}
              className="saveIcon"
          />          
          </div>
          <div className="inputContainer">
            <input 
              className="accountPageInput" 
              type="date" 
              value={editableDetails.birthDate} 
              onChange={(e) => setEditableDetails({...editableDetails, birthDate: e.target.value})} 
            />
            <img 
                src={saveIcon} 
                alt="Save Icon" 
                onClick={handleSaveChanges}
                className="saveIcon"
            />           
            </div>
            <header id="changePassword">Change Password</header>
            <div className='inputContainer'>
            <input 
                  className="accountPageInput" 
                  type="password" 
                  placeholder="Current Password"
                  value={passwords.currentPassword} 
                  onChange={(e) => setPasswords({...passwords, currentPassword: e.target.value})} 
              />
              <input 
                className="accountPageInput" 
                type="password" 
                placeholder="New Password"
                value={passwords.newPassword} 
                onChange={(e) => setPasswords({...passwords, newPassword: e.target.value})} 
              />
              <input 
                className="accountPageInput" 
                type="password" 
                placeholder="Confirm New Password"
                value={passwords.confirmNewPassword} 
                onChange={(e) => setPasswords({...passwords, confirmNewPassword: e.target.value})} 
              />
            <img
              src={saveIcon}
              alt="Save Icon"
              onClick={handleSavePassword}
              className="saveIcon"
            />
          </div>
          <header className='errorOrSuccess'>{message}</header>
          <header className='leaveMessage'>Want to leave?</header>
          <span onClick={handleDeleteAccount} id="deleteAccount">Delete account</span>
        </div>
      </div>
    </div>
  );
}

export default AccountPage;
