import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import '../styles/homepage.css';

function HomePage() {
    return (
      <div className='homePageFrame'>
        <img src={logoImage} className="logoImageHome" />
        <div className='homePage'>
          <div className='homePageContainer'>
            <header className='homePageText'>Cutty Home Page</header>
            <span className='homePageLowerText'>This is a placeholder for a home page</span>
          </div>
        </div>
      </div>
    )
  }

export default HomePage;