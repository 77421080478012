import React, { useState, useEffect } from 'react'; 
import { getDatabase, ref, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/nodeQuestion.css'; // Make sure to create this CSS file
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';

function NodeUpdatedPage3() {
  const [selectedOption, setSelectedOption] = useState('');
  const [bottomText, setBottomText] = useState(''); 
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOption === '') {
      setBottomText('Please select an option');
    } else {
      setBottomText(''); // or any other message
    }
  }, [selectedOption]);

  const handleRedeemClick = () => {
    // Get the current user
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const db = getDatabase();
      const selectionRef = ref(db, `users/${user.uid}/nodes/event1/node3Selection`);

      // Write the selection to Firebase
      set(selectionRef, selectedOption)
        .then(() => {
          console.log("Selection saved successfully.");
          navigate('/node-rewards')
        })
        .catch((error) => {
          console.error("Error saving selection:", error);
        });
    } else {
      console.log('No user is signed in.');
      navigate('/')
    }
  };

  const isOptionSelected = selectedOption !== '';

  return (
    <div className="frameQuestion">
        <img src={logoImage} className="logoImage" />
        <div className='node-updated-bg'>
            <div className="node-updated-container">
            <div className="white-container">
                <header className='node-question-header'>Please make a selection for your node 3 reward (Bar)</header>
                <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="node-updated-dropdown"
                >
                <option value="">Select an option...</option>
                <option value="Cutty Cooler">Cutty Cooler (Grey Goose)</option>
                <option value="Henny Hunt">Henny Hunt (Hennessy)</option>
                <option value="Tech on the Rocks">Tech on The Rocks (Casa Amigos Blanco)</option>
                <option value="Scavenger Spritz">Scavenger Spritz (Champagne)</option>
                <option value="No Thanks">No hangovers for me, thanks!</option>

                </select>
                <button 
                  className='redeemNodeProgress' 
                  onClick={handleRedeemClick}
                  disabled={!isOptionSelected}  // Disable the button if no option is selected
                >          
                Redeem
                </button>              
                </div>
              <span>{bottomText}</span>
            </div>
        </div>
    </div>
  );
}

export default NodeUpdatedPage3;
