import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // import useNavigate instead of useHistory
import '../styles/CreateAccount.css';
import app from '../firebase.js'
import { getDatabase, ref, set } from "firebase/database"; 
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';

function CreateAccount() {
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 

  const database = getDatabase(app);
  const users = ref(database, 'users');

  const navigate = useNavigate(); // useNavigate instead of useHistory

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(password !== confirmPassword) {
      setErrorMessage("Passwords do not match!"); // setting error message
      return;
    }
  
    const auth = getAuth(app);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
    
      const userRef = ref(getDatabase(app), 'users/' + user.uid);
      set(userRef, {
        username: username,
        email: email,
        phoneNumber: phoneNumber,
        birthDate: birthDate,
        userID: user.uid,
        points: 0,
      });
      setErrorMessage("");
      navigate('/landingpage'); 
    
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage("The email address is already in use by another account.");
      } else {
        setErrorMessage("Error signing up, please ensure all details are in the correct format.");
      }
      console.error("Error signing up with email and password", error);
    }
  };

  return (
    <div className="createAccountBackground">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div className="createAccountContainer">
        <header className='signUpHeader'>Sign up to begin your journey!</header>
        <h3>Enter your personal details to get started (keep in mind you can change this information at any point on the account page!)</h3>
        <form onSubmit={handleSubmit}>
          <input 
              type="text" 
              placeholder="Username" 
              value={username} 
              onChange={e => setUsername(e.target.value)} 
            />
          <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          <InputMask
            mask="(999) 999-9999" 
            placeholder="Phone Number" 
            value={phoneNumber} 
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h5 style={{marginRight: '10px'}}>Birthday (optional)</h5>
            <input id="birthDay" type="date" max="2002-01-01" placeholder="Age" value={birthDate} onChange={e => setBirthDate(e.target.value)} />
          </div>
          <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          <button type="submit" id="signUp">Create Account</button>
        </form>
        {errorMessage && <h6 className="error">{errorMessage}</h6>} {/* Show error message if it exists */}
        <h4>Already have a Cutty account?</h4>
        <Link to="/" id="backToLogin">Log in</Link>
      </div>
    </div>
  );
}

export default CreateAccount;