import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/landingPage.css';
import logoImage from '../assets/cuttytext.png'; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database"; 
import landingVideo from '../assets/CuttyTeaser.mp4'

function LandingPage() {
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const db = getDatabase();
        const nodesRef = ref(db, 'users/' + userId + '/nodes/event1');

        get(nodesRef).then((snapshot) => {
          if (!snapshot.exists()) {
            set(nodesRef, {
              node1: "unvisited",
              node2: "unvisited",
              node3: "unvisited",
              points: 0,
              fullyCompleted: false,
              reward: "none"
            });
          }
        });
      }
    });
  }, []);

  return (
    <div className="landingPageBackground">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div className="landingPageContainer">
        <h2>Welcome to Cutty!</h2>
        <h3>Explore more, spend less</h3>
        <video 
          src={landingVideo} 
          className="landingVideo" 
          playsInline
          muted
          controls
        />
        <div className="buttonContainer">
          <Link to="/justincredible-survey" className="landingButton">Begin Hunting</Link>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
