import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import '../styles/nodeRewardsPage.css'; // Make sure to create this CSS file

function NodeRewardsPage() {
  const [selections, setSelections] = useState({
    node1Selection: '',
    node2Selection: '',
    node3Selection: '',
  });
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);


  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const db = getDatabase();
      const selectionsRef = ref(db, `users/${user.uid}/nodes/event1`);

      get(selectionsRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setSelections({
            node1Selection: data.node1Selection || 'No selection',
            node2Selection: data.node2Selection || 'No selection',
            node3Selection: data.node3Selection || 'No selection',
          });
        } else {
          console.log("No selections available.");
        }
      }).catch((error) => {
        console.error("Error fetching selections:", error);
      });
    } else {
      console.log('No user is signed in.');
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="frameNodeRewards">
      <img src={logoImage} className="logoImage" alt="Logo" />
      <div className="menuIcon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
          ☰
          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div onClick={() => navigate('/leaderboard')}>Leaderboard</div>
              <div onClick={() => navigate('/node-rewards')}>Node Rewards</div>
            </div>
          )}
        </div>
      <div className='node-rewards-background'>
        <div className="node-rewards-container">
            <header className='node-rewards-header'>Your Node Rewards</header>
            <div className="rewards-list">
            <div className="reward-item">
                <span>Node 1 Reward:</span> <span>{selections.node1Selection}</span>
            </div>
            <div className="reward-item">
                <span>Node 2 Reward:</span> <span>{selections.node2Selection}</span>
            </div>
            <div className="reward-item">
                <span>Node 3 Reward:</span> <span>{selections.node3Selection}</span>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default NodeRewardsPage;
