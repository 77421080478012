import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import '../styles/displayPage.css';
import { IoIosArrowBack } from "react-icons/io";
import { magic } from '../magic.js'
import { getDatabase, ref, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import TokenArt1 from '../assets/TokenArt/1-Single-Square.mp4';
import TokenArt2 from '../assets/TokenArt/2-Single-Square.mp4';
import TokenArt3 from '../assets/TokenArt/3-Single-Square.mp4';
import TokenArt4 from '../assets/TokenArt/4-Single-Square.mp4';
import TokenArtStatic1 from '../assets/TokenArt/C-1.jpg';
import TokenArtStatic2 from '../assets/TokenArt/C-2.jpg';
import TokenArtStatic3 from '../assets/TokenArt/C-3.jpg';
import TokenArtStatic4 from '../assets/TokenArt/C-4.jpg';

function DisplayPage() {
  const [walletAddress, setWalletAddress] = useState('');
  const [rewardTier, setRewardTier] = useState('');
  const [currentTokenText, setCurrentTokenText] = useState("Redeem to claim reward!");
  const [flipCardText, setFlipCardText] = useState("You have not yet redeemed your digital collectible!")
  const [isFlipped, setIsFlipped] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const cardRef = useRef(null); // useRef for the flip card
  const navigate = useNavigate();

  const flipCard = () => {
    setIsFlipped(prevState => !prevState); // Toggle the flip state
  };

  const handleCardClick = () => {
    flipCard();
  };

  useEffect(() => {
    const fetchWalletAddress = async () => {
      const auth = getAuth();
      const uid = auth.currentUser?.uid;
      if (uid) {
        const db = getDatabase();
        const walletAddressRef = ref(db, `users/${uid}/walletAddress`);
        const rewardRef = ref(db, `users/${uid}/nodes/event1/reward`);
        const snap = await get(walletAddressRef);
        
        if (snap.exists()) {
          setWalletAddress(snap.val());
        }

        get(rewardRef).then((snapshot) => {
          if (snapshot.exists()) {
            setRewardTier(snapshot.val());
          }
        });
      }
    }
    
    fetchWalletAddress();
    

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        } else {
          setWalletAddress('');
        }
      });
    }

    switch (rewardTier) {
      case 'tier1':
        setCurrentTokenText("Cutty #001");
        setFlipCardText("Congratulations, you've claimed a Tier 1 Digital Collectible (the third highest tier). <br \><br \> This digital collectible represents your progress on Cutty.");
        break;
      case 'tier2':
        setCurrentTokenText("Cutty #002");
        setFlipCardText("Congratulations, you've claimed a Tier 2 Digital Collectible (the second highest tier). <br \><br \> This digital collectible represents your progress on Cutty.");
        break;
      case 'tier3':
        setCurrentTokenText("Cutty #003");
        setFlipCardText("Congratulations, you've claimed a Tier 3 Digital Collectible (the highest tier). <br \><br \> This digital collectible represents your progress on Cutty.");
        break;
      default:
        setCurrentTokenText("Redeem to claim reward!");
        break;
    }
  }, [rewardTier]);
  

  const getRewardVideoClass = () => {
    return isFlipped ? 'hidden' : 'visible';
  };

  const getFlipCardTextClass = () => {
    return isFlipped ? 'hidden' : 'visible';
  };

  const getRewardVideo = () => {
    switch (rewardTier) {
      case 'tier1':
        return TokenArt2;
      case 'tier2':
        return TokenArt3;
      case 'tier3':
        return TokenArt4;
      default:
        return null; // or a default image/video
    }
  };

  const disconnectWallet = async () => {
    setWalletAddress('');

    // Remove from Firebase
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
    if (uid) {
      const db = getDatabase();
      const walletAddressRef = ref(db, `users/${uid}/walletAddress`);
      set(walletAddressRef, '');

      // Log out from Magic
      await magic.user.logout();
    }

    // Navigate back to /walletconnect
    navigate('/walletconnect');
  };

  const connectWallet = () => {
    if (walletAddress) {
      disconnectWallet(); // if a wallet is connected, disconnect it
    } else {
      navigate('/walletconnect'); // if no wallet is connected, go to the wallet connect page
    }
  };

  return (
    <div className='frameWallet'>
      <img src={logoImage} className='logoImage' alt='Logo' />
      <div className="menuIcon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
          ☰
          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div onClick={() => navigate('/leaderboard')}>Leaderboard</div>
              <div onClick={() => navigate('/node-rewards')}>Node Rewards</div>
            </div>
          )}
        </div>
      <div className='displayPageBackground'>
      <div className='currentHeldNFT'>
        <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} ref={cardRef} onClick={flipCard}>
          <div className="card-face front">
            <header className={`cuttyCardFlipText ${getFlipCardTextClass()}`}>Tap for details</header>
            <video className={`rewardVideo ${getRewardVideoClass()}`} src={getRewardVideo()} muted autoPlay loop playsInline/>
            <header className={`cuttyCurrentNFT ${getFlipCardTextClass()}`}>{currentTokenText}</header>
          </div>
          <div className="card-face back">
          <p dangerouslySetInnerHTML={{ __html: flipCardText }} />
        </div>
        </div>
      </div>
        <div className='otherCuttyNFTs'>
          <header className='digitalCollectablesText'>Digital Collectibles</header>
          <div className='otherCuttyNFTsContainer'>
            <div className='otherCuttyNFTsItem'>
              <div className='itemSphere'>
                <img className='previewRewardStaticImage' src={TokenArtStatic2} alt="Cutty #001" />
              </div>
              <span className='otherCuttyNFTsItemText'>
                  <p>Cutty #001</p>
              </span>
              <span className='otherCuttyNFTsItemText2'>
                  <p>Tier 1 Reward</p>
              </span>
            </div>
            <div className='otherCuttyNFTsItem'>
              <div className='itemSphere'>
                <img className='previewRewardStaticImage' src={TokenArtStatic3} alt="Cutty #001" />
              </div>
              <span className='otherCuttyNFTsItemText'>
                  <p>Cutty #002</p>
              </span>
              <span className='otherCuttyNFTsItemText2'>
                  <p>Tier 2 Reward</p>
              </span>
            </div>
            <div className='otherCuttyNFTsItem'>
              <div className='itemSphere'>
                <img className='previewRewardStaticImage' src={TokenArtStatic4} alt="Cutty #001" />
              </div>
              <span className='otherCuttyNFTsItemText'>
                  <p>Cutty #003</p>
              </span>
              <span className='otherCuttyNFTsItemText2'>
                  <p>Tier 3 Reward</p>
              </span>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
}

//<div className='disconnectWalletContainer'>
//<button className="connectWalletButton" onClick={connectWallet}>
//{walletAddress ? 'Disconnect Wallet' : 'Connect Wallet'} {/* Dynamically set button text */}
//</button>
//</div>

export default DisplayPage;
