import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenArt1 from '../assets/TokenArt/1-Single.mp4';
import TokenArt2 from '../assets/TokenArt/2-Single.mp4';
import TokenArt3 from '../assets/TokenArt/3-Single.mp4';
import TokenArt4 from '../assets/TokenArt/4-Single.mp4';

function RewardVideoPage({ videoSource }) {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();

      videoRef.current.onended = () => {
        navigate('/rewards');
      };
    }
  }, [navigate]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <video ref={videoRef} src={videoSource} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

export default RewardVideoPage;
