import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import '../styles/leaderboard.css'; // assuming you might want a separate CSS file
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';

function Leaderboard() {
  const [users, setUsers] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const database = getDatabase();
    const usersRef = ref(database, 'users');
  
    onValue(usersRef, snapshot => {
      const data = snapshot.val();
      const userList = [];
      for (let userId in data) {
        userList.push({
          username: data[userId].username, // directly use the username from database
          points: parseInt(data[userId].points) // assuming points are stored as integers
        });
      }
  
      // Sorting by points in descending order and getting top 15
      const topUsers = userList.sort((a, b) => b.points - a.points).slice(0, 16);
      setUsers(topUsers);
    });
  }, []);


  return (
    <div className="frameLeaderboard">
        <img src={logoImage} className="logoImage" alt="Logo" />
        <div className="menuIcon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
          ☰
          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div onClick={() => navigate('/leaderboard')}>Leaderboard</div>
              <div onClick={() => navigate('/node-rewards')}>Node Rewards</div>
            </div>
          )}
        </div>
        <div className="leaderboardBackground">
          <header className='leaderboardText'>Leaderboard</header>
          <div className="leaderboardContainer">
            {users.map((user, index) => (
              <div key={index} className="userEntry">
                <div className="rank">{index + 1}.</div>
                <div className="username">@{user.username}</div> {/* This line displays the username */}
                <div className="points">Cutty points: {user.points}</div>
              </div>
            ))}
          </div>
        </div>
    </div>
  );  
}

export default Leaderboard;
