import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import Modal from 'react-modal';
import { icon as leafletIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/mapPage.css';
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import myIconInactiveImage from '../assets/newAssets/icons/locationInactive.png';

const defaultLocation = [36.1716, -115.1391]; 

Modal.setAppElement('#root'); 

const markers = [
  { position: [36.1716, -115.1391], title: 'Justin Credible fans,', description: 'Welcome to Cutty!' },
];

const myIconInactive = leafletIcon({
  iconUrl: myIconInactiveImage, 
  iconSize: [89, 100], 
});

const MyMapComponent = () => {
  const [userLocation, setUserLocation] = useState(defaultLocation);
  const [locationFound, setLocationFound] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: 'Location', description: 'Address or some other text here...' });
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef();
  const navigate = useNavigate();

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
 
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      //setUserLocation([position.coords.latitude, position.coords.longitude]);
      setLocationFound(true);
    }, () => {
      console.error('Unable to retrieve your location. Defaulting to Los Angeles.');
    });
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
  
    useEffect(() => {
      // Use flyTo for smooth zoom and pan
      map.flyTo(center, zoom, {
        animate: true,
        duration: 2.5 // Duration in seconds
      });
    }, [center, zoom, map]);
  
    return null;
  };

  return (
    <div className="frameMap">
      <img src={logoImage} className="logoImage" />
        <div className="menuIcon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
          ☰
          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div onClick={() => navigate('/leaderboard')}>Leaderboard</div>
              <div onClick={() => navigate('/node-rewards')}>Node Rewards</div>
            </div>
          )}
        </div>
      <div className='mapContainer'>
        <MapContainer center={userLocation} zoom={12} style={{ height: "100vh", width: "100%" }}>

          <ChangeView center={userLocation} zoom={16} />
          <TileLayer
            url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png'
            maxZoom='20'
            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          />
            {markers.map((marker, index) => (
            <Marker key={index} icon={myIconInactive} position={marker.position} eventHandlers={{ 
              click: () => {
                setModalContent({ title: marker.title, description: marker.description });
                openModal();
              }
            }}/>
          ))}
        </MapContainer>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="My Dialog"
          className="ReactModal__Content"
          overlayClassName="ReactModal__Overlay"
        >
          <div className="popupContainer">
            <header className='popupTitle'>{modalContent.title}</header>
            <p>{modalContent.description}</p>
            <button onClick={closeModal}>Continue Hunting</button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const ChangeView = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);

  return null;
}

export default MyMapComponent;
