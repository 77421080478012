import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function RedeemRewards() {
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const auth = getAuth();

    onAuthStateChanged(auth, user => {
      if (user) {
        const userId = user.uid;
        const pointsRef = ref(db, `users/${userId}/nodes/event1/points`);

        get(pointsRef).then((snapshot) => {
          if (snapshot.exists()) {
            const points = snapshot.val();
            let rewardTier = '';

            if (points >= 3) {
              rewardTier = 'tier3';
            } else if (points === 2) {
              rewardTier = 'tier2';
            } else if (points === 1) {
              rewardTier = 'tier1';
            }

            if (rewardTier) {
              const updates = {};
              updates[`/users/${userId}/nodes/event1/reward`] = rewardTier;

              update(ref(db), updates)
                .then(() => {
                  console.log(`Reward ${rewardTier} assigned.`);
                  navigate('/rewards');
                })
                .catch((error) => {
                  console.error("Failed to update reward:", error);
                });
            } else {
              console.log("Not enough points for a reward.");
            }

          } else {
            console.log("No points data available");
            // Handle case where there are no points data
          }
        }).catch((error) => {
          console.error("Error fetching points data:", error);
        });

      } else {
        console.log('No user is signed in.');
      }
    });
  }, [navigate]);

  return null;
}

export default RedeemRewards;
